/* global window document navigator */
'use strict';

import JsonFetch from './json-fetch';
import ObjectAssign from 'object-assign';

//in case we link w GA
const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8); return v.toString(16);
});

const url = '/api/logs';

const getClientDetails = () => {
    const languages = navigator.languages ? navigator.languages.join(',') : navigator.language;
    const details = {
        app_version: navigator.appVersion,
        languages,
        platform: navigator.platform,
        user_agent: navigator.userAgent,
        vendor: navigator.vendor
    };
    return details;
};

const log = (name, details, time, done, client_log_only) => {
    const path = document.location.toString();
    time = time || + new Date();

    const event = ObjectAssign(getClientDetails(), {
        time,
        name,
        guid,
        details,
        path,
        client_log_only
    });
    const request = { method: 'POST', url, data: event };
    JsonFetch(request, (err, response) => {
        if ( err ){
            window.console && console.log(err);
            if ( done ) {
                done(err);
            }
        } else {
            if ( done ){
                done(null, response);
            }
        }
    });
    return time;
};

log.getClientDetails = getClientDetails;
log.url = '/api/logs';

export default log;
