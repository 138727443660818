/* global window, document, navigator, localStorage */


import Cookie from 'cookie';
import Qs from 'qs';
import Xhr from 'xhr';
import Log from './log';
import { API_VERSION, APP_VERSION } from '../../shared/api';

const redirect = function () {
    let returnUrl = window.location.pathname;

    if (window.location.search.length > 0) {
        returnUrl += window.location.search;
    }

    returnUrl = encodeURIComponent(returnUrl);

    window.location.href = `/login?returnUrl=${returnUrl}`;
};

const HttpError = function (rawRequest, connectionError) {
    this.name = 'HttpError';
    this.message = rawRequest.statusText;
    this.statusText = rawRequest.statusText;
    this.statusCode = rawRequest.status;
    this.stack = (new Error()).stack;
    this.connectionError = connectionError;
    this.headers = rawRequest.headers;
};

HttpError.prototype = new Error();

//some jwplayer errors have circular references
const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }

            seen.add(value);
        }

        return value;
    };
};

//IOS Safari will lose access to the cookies via javascript
//on kill (via double click and swipe up).  When Safari is restarted
//the cookies will still be there but not be readable from javascript.
let originalCrumb = 'unread';
if ( typeof document !== 'undefined') {
    const cookies = Cookie.parse(document.cookie);
    originalCrumb = cookies && cookies.crumb;
    if ( originalCrumb ) {
        localStorage.setItem('bf-crumb', originalCrumb);
    }
}

const jsonFetch = function (options, callback) {

    const cookies = Cookie.parse(document.cookie);
    const config = {
        url: options.url,
        method: options.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...options.headers,
            'api-version': API_VERSION
        }
    };

    // NOTE: if we ever need to make cross origin request from PC to another sub-domain, we need to pass in crossOrigin in options to true
    // then if it's true don't include crumb, X-PC-Commit and X-PC-Release in header

    if (cookies.crumb) {//beware of calling in the same tick when crumbjs cookie changes
        //you can get an old crumb here as the X-CSRF-token but the new request has the new crumb as the cookie
        config.headers['X-CSRF-Token'] = cookies.crumb;
    } else {
        const saved = localStorage.getItem('bf-crumb');
        if ( saved ) {
            config.headers['X-CSRF-Token'] = saved;
        } else {
            //Don't log here.  It will get recursive.
            //Log('Crumb is missing', { message: 'Crumb missing', cookie: document.cookie });
            config.headers['X-CSRF-Token'] = 'crumb missing. oc:--' + originalCrumb + '--';
            console.log('Crumb is missing', document.cookie);
        }
    }

    config.headers['X-PC-Commit'] = __COMMIT_HASH__;
    config.headers['X-PC-Release'] = __RELEASE__;
    config.headers['X-PC-App-Version'] = APP_VERSION;

    if (options.query) {
        config.url += '?' + Qs.stringify(options.query);
    }

    if (options.data) {
        try {
            config.body = JSON.stringify(options.data);
        }
        catch (err) {
            console.log('circular reference?', options.data, err);
            config.body = JSON.stringify(options.data, getCircularReplacer());
        }
    }

    //return in case you want to call abort
    return Xhr(config, (err, response, body) => {

        ///if browser can't contact err will be not null.  This does not mean 500 errors, those got to the server
        ///if we are 200 <= statusCode < 300 we parse the body and return with x-auth-required exception
        ///if we are statusCode >= 300 we create our own Error from the http status
        ///if Heroku returns an error I'm not it will have accompanying JSON
        try {

            if (err) {
                const error = new HttpError(response.rawRequest, err);
                if ( !navigator.onLine || response.statusCode === 0) {//where is this used?
                    error.message = 'App appears to be offline. Please try again or refresh your browser.';
                    return callback(error);
                }

                error.message = 'There is an unknown issue contacting the server';
                return callback(error);
            }

            if (response.statusCode === 401 && options.redirectOn401 ) {
                //probably all the api calls should use this but they are not.  They use redirect because that's
                //the hapi-auth-cookie default.  for the ones I want retries I will need this.
                redirect();
            }
            else if (response.statusCode >= 200 && response.statusCode < 300) {
                if (response.headers.hasOwnProperty('x-auth-required')) {
                    redirect();
                }
                else if ( config.method === 'HEAD') {
                    const head = {};
                    const headers = response.headers;
                    Object.keys(headers).forEach((key) => {
                        //convert to S3 style
                        const s3StyleKey = key.split('-').map((p) => {return p.charAt(0).toUpperCase() + p.slice(1);}).join('');
                        head[s3StyleKey] = headers[key];
                    });
                    callback(null, head, response);
                }
                else {
                    //I wonder if it would be better to call the callback always
                    //in the catch at the bottom of this file.  This less risky for now
                    let json = body;
                    let error = null;
                    try {
                        json = JSON.parse(body);
                    }
                    catch (e) {
                        //Log('Error', { message: e.message, body });
                        console.log('could not parse body', body);
                        json = {
                            message: e.message,
                            requestBody: body//throw this on here and hope it shows up in the logs
                        };
                        //now this error should show up with the caller
                        error = new HttpError(response.rawRequest);//this isn't really a server error, well it kind of is cause expect JSON
                    }

                    callback(error, json, response);
                }
            }
            else {
                //const httpErr = new Error(response.rawRequest.statusText);
                const httpErr = new HttpError(response.rawRequest);
                let json = body;
                try {
                    json = JSON.parse(body);//Hapi returns json in the body.  I'm not sure Heroku does
                }
                catch (e) { //bury
                }

                callback(httpErr, json, response);
            }
        }
        catch (e) {
            if ( config.url === Log.url ) {
                //bury this cause we don't want get into a loop where we throw an error and error-catch catches it
                //and logs and we throw it again.
                console.log('Error in Json Fetch', e);
            }
            else {
                throw e;
            }

        }
    });
};

jsonFetch.HttpError = HttpError;

if ( global.window ){
    window.jsonFetch = jsonFetch;
}

export default jsonFetch;
